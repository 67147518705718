<script lang="ts">
import type { RouteLocationRaw } from '#vue-router';
import IconArrowRight from '~theme/assets/icons/outline/arrow-ios-right.svg?component';

export type Breadcrumbs = {
  label: string;
  location?: RouteLocationRaw;
  description: string;
}[];
</script>

<script setup lang="ts">
const props = defineProps<{ breadcrumbs: Breadcrumbs }>();

const {
  public: { appOrigin },
} = useRuntimeConfig();
const route = useRoute();
const currentCanonicalUrl = appOrigin + route.path.replace(/\/+$/, '');

const router = useRouter();
const toString = (routeLocation: RouteLocationRaw) =>
  router.resolve(routeLocation).fullPath;

useJsonld(
  () => ({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: props.breadcrumbs.map((b, index) => {
      const url = b.location
        ? appOrigin + toString(b.location).replace(/\/+$/, '')
        : currentCanonicalUrl;

      return {
        '@type': 'ListItem',
        position: index + 1,
        name: b.label,
        item: {
          '@type': 'Thing',
          '@id': url,
          name: b.label,
          description: b.description,
        },
      };
    }),
  }),
  {
    tagPosition: 'bodyClose',
  }
);
</script>

<template>
  <div :class="$style.wrapper">
    <div :class="$style.wrapper__layout">
      <template v-for="(b, i) in breadcrumbs" :key="b.label">
        <IconArrowRight v-if="i !== 0" :class="$style.wrapper__divider" />
        <span
          v-if="!b.location || i === breadcrumbs.length - 1"
          :class="$style.wrapper__item"
          >{{ b.label }}</span
        >
        <NuxtLink v-else :class="$style.wrapper__item" :href="b.location">{{
          b.label
        }}</NuxtLink>
      </template>
    </div>
  </div>
</template>

<style lang="scss" module>
.wrapper {
  width: 100%;

  &__layout {
    margin: 0 auto;
    overflow: auto;
    display: flex;
    align-items: center;
    padding: 8px 0;
    gap: 8px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    flex-shrink: 0;
    font-size: 12px;
    color: $color-text-and-object-medium-emphasis;
    &:nth-last-child(1) {
      font-weight: bold;
    }
  }

  &__divider {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }
}
</style>
